import React from 'react'
import { Form, Button, Spinner, Alert } from 'react-bootstrap'
import { useDispatch, useSelector } from "react-redux";
import { setEmail, setPassword } from '../actions/usuariosActions';
import { setResponseAPI, setLoading, setValidated, setVariantAlert } from '../actions/applicationActions';
import { ApiPost } from '../functions/ApiPost'
import { useHistory } from "react-router-dom";
import { NavigationBar } from '../components/NavigationBar'

export const LogIn = (props) => {
    const dispatch = useDispatch();
    const { email, password } = useSelector(state => state.usuariosReducer);
    const { responseAPI, variantAlert, loading, validated } = useSelector(state => state.applicationReducer);

    let history = useHistory();

    const handleChange = (event) => {
        event.preventDefault();

        dispatch(setResponseAPI(""))

        if (event.target.id === "formEmail") {
            dispatch(setEmail(event.target.value))
        } else if (event.target.id === "formPassword") {
            dispatch(setPassword(event.target.value))
        }
    }

    // envio do formulário
    const handleSubmit = (event) => {
        event.preventDefault();

        /* zera variável de exibição do feedback do envio ao usuário */
        dispatch(setResponseAPI(""));

        // validação preenchimento campos obrigatórios
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        /* assinala o formulário como validado */
        dispatch(setValidated(true))

        if (form.checkValidity() === true) {
            /* atualiza status de loading no botão de envio */
            dispatch(setLoading(true))

            /* cria objeto com dados que serão enviados */
            const dataToSubmit = {
                email,
                password
            }

            /* loga o usuário */
            ApiPost(dataToSubmit, 'login')
                .then((retorno) => {
                    if (retorno) {
                        dispatch(setLoading(retorno.loading));
                        dispatch(setResponseAPI(retorno.responseAPI))
                        dispatch(setVariantAlert(retorno.variantAlert));

                        sessionStorage.clear()
                        sessionStorage.setItem('isAuthenticated','true')
                        sessionStorage.setItem('token',retorno.token)

                        history.push('/')
                    }
                })
                .catch((error) => {
                    if (error) {
                        dispatch(setLoading(error.loading));
                        dispatch(setResponseAPI(error.responseAPI))
                        dispatch(setVariantAlert(error.variantAlert));
                    }
                })
        }
    }

    return (
        <div>
            <NavigationBar />
            <div>
                {(responseAPI !== undefined && responseAPI !== "" && responseAPI !== " ") ? <Alert variant={variantAlert}> {responseAPI} </Alert> : <span></span>}

                <br />
                <h1>Login</h1>

                <Form onSubmit={handleSubmit} noValidate validated={validated}>
                    <Form.Group controlId="formEmail" value={email} onChange={handleChange}>
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="text" required />
                        <Form.Control.Feedback type="invalid">Por favor, informe o e-mail.</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formPassword" value={password} onChange={handleChange}>
                        <Form.Label>Senha</Form.Label>
                        <Form.Control type="password" required />
                        <Form.Control.Feedback type="invalid">Por favor, informe a senha.</Form.Control.Feedback>
                    </Form.Group>

                    <Button variant="success" type="submit" disabled={loading}>
                        {loading && (
                            <Spinner animation="border" variant="warning" size="sm" />
                        )}
                        {loading && <span> Logando...</span>}
                        {!loading && <span>Logar</span>}
                    </Button>
                </Form>
                <br />
            </div>
        </div>
    )
}