export const SET_DENOMINACAO = "SET_DENOMINACAO";
export const SET_VALOR = "SET_VALOR";

export const setDenominacao = (denominacao) => ({
  type: SET_DENOMINACAO,
  payload: denominacao
});

export const setValor = (valor) => ({
  type: SET_VALOR,
  payload: valor
});

