import {
  SET_DESCRICAO
} from "../actions/origensActions.js";

const initialState = {
  descricao: ""
};

export const origensReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DESCRICAO:
      return {
        ...state,
        descricao: action.payload
      };
    default:
      return state;
  }
};
