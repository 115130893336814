import React from 'react';
import { NavigationBar } from '../components/NavigationBar'

export const Unauthorized = () => {
    return (
        <div>
            <NavigationBar />
            <h1>403 - Acesso Negado</h1>
        </div>
    )
}
