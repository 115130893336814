import {
  SET_RESPONSE_API,
  SET_VARIANT_ALERT,
  SET_LOADING,
  SET_VALIDATED
} from "../actions/applicationActions.js";

const initialState = {
  responseAPI: "",
  variantAlert: "",
  loading: "",
  validated: ""
};

export const applicationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_RESPONSE_API:
      return {
        ...state,
        responseAPI: action.payload
      };
    case SET_VARIANT_ALERT:
      return {
        ...state,
        variantAlert: action.payload
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case SET_VALIDATED:
      return {
        ...state,
        validated: action.payload
      };
    default:
      return state;
  }
};
