import {
  SET_ORCAMENTO,
  SET_ORIGEM,
  SET_CENTROCUSTO,
  SET_DATA,
  SET_VALOR,
  SET_DESCRICAO
} from "../actions/lancamentosActions";

const initialState = {
  orcamento: "",
  origem: "",
  centroCusto: "",
  data: "",
  valor: 0,
  descricao: ""
};

export const lancamentosReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ORCAMENTO:
      return {
        ...state,
        orcamento: action.payload
      };
    case SET_ORIGEM:
      return {
        ...state,
        origem: action.payload
      };
    case SET_CENTROCUSTO:
      return {
        ...state,
        centroCusto: action.payload
      };
    case SET_DATA:
      return {
        ...state,
        data: action.payload
      };
    case SET_VALOR:
      return {
        ...state,
        valor: action.payload
      };
    case SET_DESCRICAO:
      return {
        ...state,
        descricao: action.payload
      };
    default:
      return state;
  }
};
