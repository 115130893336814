import React from 'react'
import { Button, Spinner, Alert, ListGroup } from 'react-bootstrap'
import { useDispatch, useSelector } from "react-redux";
import { setResponseAPI, setLoading, setVariantAlert } from '../actions/applicationActions';
import { ApiDelete } from '../functions/ApiDelete'
import { useLancamentos } from '../hooks/useLancamentos'
import { NavigationBar } from '../components/NavigationBar'

export const LancamentoListar = () => {
    let lancamentos = useLancamentos()

    const dispatch = useDispatch();
    const { responseAPI, variantAlert, loading } = useSelector(state => state.applicationReducer);

    // exclusão de um lançamento
    const handleExclusao = (id) => {
        /* zera variável de exibição do feedback do envio ao usuário */
        dispatch(setResponseAPI(""));

        /* atualiza status de loading no botão de envio */
        dispatch(setLoading(true))

        /* cria objeto com dados que serão enviados */
        const dataToSubmit = {
            id
        }

        /* deleta lançamento */
        ApiDelete(dataToSubmit, 'lancamento')
            .then((retorno) => {
                if (retorno) {
                    dispatch(setLoading(retorno.loading));
                    dispatch(setResponseAPI(retorno.responseAPI))
                    dispatch(setVariantAlert(retorno.variantAlert));
                }
            })
            .catch((error) => {
                if (error) {
                    dispatch(setLoading(error.loading));
                    dispatch(setResponseAPI(error.responseAPI))
                    dispatch(setVariantAlert(error.variantAlert));
                }
            })
    }

    return (
        <div>
            <NavigationBar />

            <div>
                {(responseAPI !== undefined && responseAPI !== "" && responseAPI !== " ") ? <Alert variant={variantAlert}> {responseAPI} </Alert> : <span></span>}
                <br />
                <h3>Lançamentos</h3>
                <ListGroup>
                    {lancamentos.map(lancamento => (
                        <ListGroup.Item key={lancamento._id}>
                            <p>Orçamento {lancamento.orcamento.denominacao} - Origem {lancamento.origem.descricao} - C. Custo {lancamento.centroCusto.descricao}</p>
                            <p>Data {lancamento.data.substring(0, 10)}</p>
                            <p>Valor {lancamento.valor}</p>
                            {lancamento.descricao ? <p>Descrição {lancamento.descricao}</p> : ""}
                            <Button variant="danger" disabled={loading} onClick={(e) => handleExclusao(lancamento._id, e)}>
                                {loading && (<Spinner animation="border" variant="warning" size="sm" />)}
                                Excluir
                            </Button>
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            </div>
        </div>
    )
}