import {
  SET_DESCRICAO
} from "../actions/centrosCustoActions";

const initialState = {
  descricao: ""
};

export const centrosCustoReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DESCRICAO:
      return {
        ...state,
        descricao: action.payload
      };
    default:
      return state;
  }
};
