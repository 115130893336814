export const SET_EMAIL = "SET_EMAIL";
export const SET_NOME = "SET_NOME";
export const SET_PASSWORD = "SET_PASSWORD";

export const setEmail = (email) => ({
  type: SET_EMAIL,
  payload: email
});

export const setNome = (nome) => ({
  type: SET_NOME,
  payload: nome
});

export const setPassword = (password) => ({
  type: SET_PASSWORD,
  payload: password
});
