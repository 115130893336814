import {
    SET_EMAIL,
    SET_NOME,
    SET_PASSWORD
} from "../actions/usuariosActions.js";

const initialState = {
    email: "",
    nome: "",
    password: ""
};

export const usuariosReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_EMAIL:
            return {
                ...state,
                email: action.payload
            };
        case SET_NOME:
            return {
                ...state,
                nome: action.payload
            };
        case SET_PASSWORD:
            return {
                ...state,
                password: action.payload
            };
        default:
            return state;
    }
};
