import React from 'react'
import { ListGroup } from 'react-bootstrap'
import { useRelatorioOrcamentos } from '../hooks/useRelatorioOrcamentos'
import { NavigationBar } from '../components/NavigationBar'

export const RelatorioOrcamento = () => {
    let relatorioOrcamentos = useRelatorioOrcamentos()

    return (
        <div>
            <NavigationBar />
            <br />
            <h3>Relatório por Orçamentos</h3>
            <h4>* Exibindo valores realizados no mês corrente.</h4>
            <ListGroup>
                {relatorioOrcamentos.map(orcamento => (
                    <ListGroup.Item key={orcamento._id}>
                        <h3>{orcamento.denominacao}</h3>
                        <p>Orçado: {orcamento.orcado}</p>
                        <p>Realizado: {orcamento.realizado}</p>
                        <p>Saldo: {orcamento.saldo}</p>
                    </ListGroup.Item>
                ))}
            </ListGroup>
        </div>
    )
}
