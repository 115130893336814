export const SET_RESPONSE_API = "SET_RESPONSE_API";
export const SET_VARIANT_ALERT = "SET_VARIANT_ALERT";
export const SET_LOADING = "SET_LOADING";
export const SET_VALIDATED = "SET_VALIDATED";

export const setResponseAPI = (responseAPI) => ({
  type: SET_RESPONSE_API,
  payload: responseAPI
});

export const setVariantAlert = (variantAlert) => ({
  type: SET_VARIANT_ALERT,
  payload: variantAlert
});

export const setLoading = (loading) => ({
  type: SET_LOADING,
  payload: loading
});

export const setValidated = (validated) => ({
  type: SET_VALIDATED,
  payload: validated
});
