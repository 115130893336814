import React from 'react'
import { Button, Spinner, Alert, ListGroup } from 'react-bootstrap'
import { useDispatch, useSelector } from "react-redux";
import { setResponseAPI, setLoading, setVariantAlert } from '../actions/applicationActions';
import { ApiDelete } from '../functions/ApiDelete'
import { useOrcamentos } from '../hooks/useOrcamentos'
import { NavigationBar } from '../components/NavigationBar'

export const OrcamentoListar = () => {
    let orcamentos = useOrcamentos()

    const dispatch = useDispatch();
    const { responseAPI, variantAlert, loading } = useSelector(state => state.applicationReducer);

    // exclusão de um orçamento
    const handleExclusao = (id) => {
        /* zera variável de exibição do feedback do envio ao usuário */
        dispatch(setResponseAPI(""));

        /* atualiza status de loading no botão de envio */
        dispatch(setLoading(true))

        /* cria objeto com dados que serão enviados */
        const dataToSubmit = {
            id
        }

        /* deleta orçamento */
        ApiDelete(dataToSubmit, 'orcamento')
            .then((retorno) => {
                if (retorno) {
                    dispatch(setLoading(retorno.loading));
                    dispatch(setResponseAPI(retorno.responseAPI))
                    dispatch(setVariantAlert(retorno.variantAlert));
                }
            })
            .catch((error) => {
                if (error) {
                    dispatch(setLoading(error.loading));
                    dispatch(setResponseAPI(error.responseAPI))
                    dispatch(setVariantAlert(error.variantAlert));
                }
            })
    }

    return (
        <div>
            <NavigationBar />
            <div>
                {(responseAPI !== undefined && responseAPI !== "" && responseAPI !== " ") ? <Alert variant={variantAlert}> {responseAPI} </Alert> : <span></span>}
                <br />
                <h3>Orçamentos</h3>
                <ListGroup>
                    {orcamentos.map(orcamento => (
                        <ListGroup.Item key={orcamento._id}>
                            <p>{orcamento.denominacao} - {orcamento.valor}</p>
                            <Button variant="danger" disabled={loading} onClick={(e) => handleExclusao(orcamento._id, e)}>
                                {loading && (<Spinner animation="border" variant="warning" size="sm" />)}
                                Excluir
                            </Button>
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            </div>
        </div>
    )
}