import {
  SET_DENOMINACAO,
  SET_VALOR
} from "../actions/orcamentosActions";

const initialState = {
  denominacao: "",
  valor: 0
};

export const orcamentosReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DENOMINACAO:
      return {
        ...state,
        denominacao: action.payload
      };
    case SET_VALOR:
      return {
        ...state,
        valor: action.payload
      };
    default:
      return state;
  }
};
