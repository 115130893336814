import React from 'react';
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { ApplicationCookies } from '../functions/ApplicationCookies'

export const NavigationBar = () => {
    const { isAuthenticated } = ApplicationCookies()

    return (
        <div>
            <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
                <Navbar.Brand href="/">My Budget</Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto">
                        <Nav.Item><Nav.Link href="/">Home</Nav.Link></Nav.Item>

                        <Nav.Item><Nav.Link className={`${isAuthenticated === "true" ? "disabled" : ""}`} href="/signup">Sign Up</Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link className={`${isAuthenticated === "true" ? "disabled" : ""}`} href="/login">Log In</Nav.Link></Nav.Item>

                        <NavDropdown title="Orçamentos" id="collasible-nav-dropdown">
                            <NavDropdown.Item className={`${isAuthenticated !== "true" ? "disabled" : ""}`} href="/orcamento">Criar</NavDropdown.Item>
                            <NavDropdown.Item className={`${isAuthenticated !== "true" ? "disabled" : ""}`} href="/orcamentolistar">Listar</NavDropdown.Item>
                        </NavDropdown>

                        <NavDropdown title="Origens" id="collasible-nav-dropdown">
                            <NavDropdown.Item className={`${isAuthenticated !== "true" ? "disabled" : ""}`} href="/origem">Criar</NavDropdown.Item>
                            <NavDropdown.Item className={`${isAuthenticated !== "true" ? "disabled" : ""}`} href="/origemlistar">Listar</NavDropdown.Item>
                        </NavDropdown>

                        <NavDropdown title="Centros de Custo" id="collasible-nav-dropdown">
                            <NavDropdown.Item className={`${isAuthenticated !== "true" ? "disabled" : ""}`} href="/centrocusto">Criar</NavDropdown.Item>
                            <NavDropdown.Item className={`${isAuthenticated !== "true" ? "disabled" : ""}`} href="/centrocustolistar">Listar</NavDropdown.Item>
                        </NavDropdown>

                        <NavDropdown title="Lançamentos" id="collasible-nav-dropdown">
                            <NavDropdown.Item className={`${isAuthenticated !== "true" ? "disabled" : ""}`} href="/lancamento">Criar</NavDropdown.Item>
                            <NavDropdown.Item className={`${isAuthenticated !== "true" ? "disabled" : ""}`} href="/lancamentolistar">Listar</NavDropdown.Item>
                        </NavDropdown>

                        <NavDropdown title="Relatórios" id="collasible-nav-dropdown">
                            <NavDropdown.Item className={`${isAuthenticated !== "true" ? "disabled" : ""}`} href="/relatorio-orcamento">Por Orçamento</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                    <Nav>
                        <Nav.Item><Nav.Link className={`${isAuthenticated !== "true" ? "disabled" : ""}`} href="/logout">Log Out</Nav.Link></Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </div>
    )
}