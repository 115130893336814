import { combineReducers } from 'redux'
import { usuariosReducer } from './usuariosReducer'
import { origensReducer } from './origensReducer.js'
import { applicationReducer } from './applicationReducer'
import { centrosCustoReducer } from './centrosCustoReducer'
import { lancamentosReducer } from './lancamentosReducer'
import { orcamentosReducer } from './orcamentosReducer'

export const reducers = combineReducers({
    usuariosReducer,
    origensReducer,
    centrosCustoReducer,
    lancamentosReducer,
    orcamentosReducer,
    applicationReducer
});
