import React, { useEffect } from 'react'
import { useHistory } from "react-router-dom";

export const LogOut = (props) => {
    let history = useHistory();

    useEffect(() => {
        sessionStorage.clear()
        sessionStorage.setItem('isAuthenticated','false')
        sessionStorage.setItem('token','')

        history.push('/')
    })

    return (
        <div>
            <h1>LogOut Page</h1>
        </div>
    )
}