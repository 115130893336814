import React from 'react'
import { Form, Button, Spinner, Alert } from 'react-bootstrap'
import { useDispatch, useSelector } from "react-redux";
import { setDescricao } from '../actions/origensActions';
import { setResponseAPI, setLoading, setValidated, setVariantAlert } from '../actions/applicationActions';
import { ApiPost } from '../functions/ApiPost'
import { NavigationBar } from '../components/NavigationBar'

export const Origem = () => {
    const dispatch = useDispatch();
    const { descricao } = useSelector(state => state.origensReducer);
    const { responseAPI, variantAlert, loading, validated } = useSelector(state => state.applicationReducer);

    const handleChange = (event) => {
        event.preventDefault();

        dispatch(setResponseAPI(""))

        if (event.target.id === "formDescricao") {
            dispatch(setDescricao(event.target.value))
        }
    }

    // envio do formulário
    const handleSubmit = (event) => {
        event.preventDefault();

        /* zera variável de exibição do feedback do envio ao usuário */
        dispatch(setResponseAPI(""));

        // validação preenchimento campos obrigatórios
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        /* assinala o formulário como validado */
        dispatch(setValidated(true))

        if (form.checkValidity() === true) {
            /* atualiza status de loading no botão de envio */
            dispatch(setLoading(true))

            /* cria objeto com dados que serão enviados */
            const dataToSubmit = {
                descricao
            }

            /* cadastra nova origem */
            ApiPost(dataToSubmit, 'origem')
                .then((retorno) => {
                    if (retorno) {
                        dispatch(setLoading(retorno.loading))
                        dispatch(setResponseAPI(retorno.responseAPI))
                        dispatch(setVariantAlert(retorno.variantAlert))
                    }
                })
                .catch((error) => {
                    if (error) {
                        dispatch(setLoading(error.loading));
                        dispatch(setResponseAPI(error.responseAPI))
                        dispatch(setVariantAlert(error.variantAlert));
                    }
                })
        }
    }

    return (
        <div>
            <NavigationBar />
            <div>
                <br />
                <h3>Cadastro de Origens</h3>

                <Form onSubmit={handleSubmit} noValidate validated={validated}>
                    <Form.Group controlId="formDescricao" value={descricao} onChange={handleChange}>
                        <Form.Label>Descrição</Form.Label>
                        <Form.Control type="text" required />
                        <Form.Control.Feedback type="invalid">Por favor, informe a descrição.</Form.Control.Feedback>
                    </Form.Group>

                    {(responseAPI !== undefined && responseAPI !== "" && responseAPI !== " ") ? <Alert variant={variantAlert}> {responseAPI} </Alert> : <span></span>}
                    
                    <Button variant="success" type="submit" disabled={loading}>
                        {loading && (<Spinner animation="border" variant="warning" size="sm" />)}
                        {loading && <span> Cadastrando...</span>}
                        {!loading && <span>Cadastrar</span>}
                    </Button>
                </Form>
                <br />
            </div>
            <br />
        </div>
    )
}