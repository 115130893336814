import React from 'react'
import { Form, Button, Spinner, Alert } from 'react-bootstrap'
import { useDispatch, useSelector } from "react-redux";
import { setOrcamento, setOrigem, setCentroCusto, setData, setValor, setDescricao } from '../actions/lancamentosActions';
import { setResponseAPI, setLoading, setVariantAlert } from '../actions/applicationActions';
import { ApiPost } from '../functions/ApiPost'
import { useOrcamentos } from '../hooks/useOrcamentos'
import { useOrigens } from '../hooks/useOrigens'
import { useCentrosCusto } from '../hooks/useCentrosCusto'
import { NavigationBar } from '../components/NavigationBar'

export const Lancamento = () => {
    let orcamentos = useOrcamentos()
    let origens = useOrigens()
    let centrosCusto = useCentrosCusto()

    const dispatch = useDispatch();
    const { orcamento, origem, centroCusto, data, valor, descricao } = useSelector(state => state.lancamentosReducer);
    const { responseAPI, variantAlert, loading } = useSelector(state => state.applicationReducer);

    const handleChange = (event) => {
        event.preventDefault();

        dispatch(setResponseAPI(""))

        if (event.target.id === "formOrcamento") {
            let selected = "";
            for (let option of document.getElementById('formOrcamento').options) {
                if (option.selected) {
                    selected = option.id;
                }
            }
            dispatch(setOrcamento(selected))
        } else if (event.target.id === "formOrigem") {
            let selected = "";
            for (let option of document.getElementById('formOrigem').options) {
                if (option.selected) {
                    selected = option.id;
                }
            }
            dispatch(setOrigem(selected))
        } else if (event.target.id === "formCentroCusto") {
            let selected = "";
            for (let option of document.getElementById('formCentroCusto').options) {
                if (option.selected) {
                    selected = option.id;
                }
            }
            dispatch(setCentroCusto(selected))
        } else if (event.target.id === "formData") {
            dispatch(setData(event.target.value))
        } else if (event.target.id === "formValor") {
            dispatch(setValor(event.target.value))
        } else if (event.target.id === "formDescricao") {
            dispatch(setDescricao(event.target.value))
        }
    }

    // envio do formulário
    const handleSubmit = (event) => {
        event.preventDefault();

        /* zera variável de exibição do feedback do envio ao usuário */
        dispatch(setResponseAPI(""));

        // validação preenchimento campos obrigatórios
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        if (form.checkValidity() === true) {
            /* atualiza status de loading no botão de envio */
            dispatch(setLoading(true))

            /* cria objeto com dados que serão enviados */
            const dataToSubmit = {
                orcamento,
                origem,
                centroCusto,
                data,
                valor,
                descricao
            }

            /* registra novo lançamento */
            ApiPost(dataToSubmit, 'lancamento')
                .then((retorno) => {
                    if (retorno) {
                        dispatch(setLoading(retorno.loading));
                        dispatch(setResponseAPI(retorno.responseAPI))
                        dispatch(setVariantAlert(retorno.variantAlert));
                    }
                })
                .catch((error) => {
                    if (error) {
                        dispatch(setLoading(error.loading));
                        dispatch(setResponseAPI(error.responseAPI))
                        dispatch(setVariantAlert(error.variantAlert));
                    }
                })
        }
    }

    return (
        <div>
            <NavigationBar />
            <div>
                <br />
                <h3>Lançamento</h3>

                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formOrcamento" value={orcamento} onChange={handleChange}>
                        <Form.Label>Orçamento</Form.Label>
                        <Form.Control as="select" required>
                            <option id="0" key="0"></option>
                            {orcamentos.map(orcamento => (<option id={orcamento._id} key={orcamento._id}>{orcamento.denominacao}</option>))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">Por favor, informe o orçamento.</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formOrigem" value={origem} onChange={handleChange}>
                        <Form.Label>Origem</Form.Label>
                        <Form.Control as="select" required>
                            <option id="0" key="0"></option>
                            {origens.map(origem => (<option id={origem._id} key={origem._id}>{origem.descricao}</option>))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">Por favor, informe a origem.</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formCentroCusto" value={centroCusto} onChange={handleChange}>
                        <Form.Label>CentroCusto</Form.Label>
                        <Form.Control as="select" required>
                            <option id="0" key="0"></option>
                            {centrosCusto.map(centroCusto => (<option id={centroCusto._id} key={centroCusto._id}>{centroCusto.descricao}</option>))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">Por favor, informe o centro de custo.</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formData" value={data} onChange={handleChange}>
                        <Form.Label>Data</Form.Label>
                        <Form.Control type="date" required />
                        <Form.Control.Feedback type="invalid">Por favor, informe a data.</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formValor" value={valor} onChange={handleChange}>
                        <Form.Label>Valor</Form.Label>
                        <Form.Control pattern="\d+(\.\d{2})?|\.\d{2}" required />
                        <Form.Text className="text-muted">Ex.: 1234.99 (informe duas casas decimais, utilizando ponto como separador decimal).</Form.Text>
                    </Form.Group>

                    <Form.Group controlId="formDescricao" value={descricao} onChange={handleChange}>
                        <Form.Label>Descrição</Form.Label>
                        <Form.Control type="text" />
                    </Form.Group>

                    {(responseAPI !== undefined && responseAPI !== "" && responseAPI !== " ") ? <Alert variant={variantAlert}> {responseAPI} </Alert> : <span></span>}

                    <Button variant="success" type="submit" disabled={loading}>
                        {loading && (<Spinner animation="border" variant="warning" size="sm" />)}
                        {loading && <span> Registrando...</span>}
                        {!loading && <span>Registrar</span>}
                    </Button>
                </Form>
                <br />
            </div>
            <br />
        </div>
    )
}