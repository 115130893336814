import React from 'react'
import {Link} from 'react-router-dom'
import { useSelector } from "react-redux";
import { Jumbotron, Container, Alert } from "react-bootstrap";
import { NavigationBar } from '../components/NavigationBar'
import { ApplicationCookies } from '../functions/ApplicationCookies'
import jwt_decode from "jwt-decode";

export const Home = () => {
    const {token, isAuthenticated } = ApplicationCookies()
    const { responseAPI, variantAlert} = useSelector(state => state.applicationReducer);

    let decoded
    let nomeUsuario

    /* nome do usuário logado */
    if (token) {
        decoded = jwt_decode(token)
        nomeUsuario = decoded.nome
    }


    return (
        <div>
            <NavigationBar />
            {(responseAPI !== undefined && responseAPI !== "" && responseAPI !== " ") ? <Alert variant={variantAlert}> {responseAPI} </Alert> : <span></span>}
            <Jumbotron fluid>
                <Container>
                    <h1>My Budget App</h1>
                    <h2>Gerenciamento de finanças pessoais.</h2>
                    <br />
                    {nomeUsuario ? <p>Seja bem vindo {nomeUsuario} </p> : <span></span>}
                    {isAuthenticated !== "true" ? <p><Link to="/signup">Cadastre-se</Link> ou realize <Link to="/login">login</Link> para iniciar.</p> : <p></p>}                    
                </Container>
            </Jumbotron>
        </div>
    )
}