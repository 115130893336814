import axios from 'axios';
import { enviromentVariables } from '../environment/variables'
import { ApplicationCookies } from './ApplicationCookies'

export const ApiPost = (dataToSubmit, entity) => {
    const { token } = ApplicationCookies()

    /* definição do endpoint conforme entidade */
    let endpoint = ""
    if (entity === "signup")
        endpoint = "usuarios/signup"
    else if (entity === "login")
        endpoint = "usuarios/login"
    else if (entity === "origem")
        endpoint = "origens"
    else if (entity === "centroCusto")
        endpoint = "centroscusto"
    else if (entity === "lancamento")
        endpoint = "lancamentos"
    else if (entity === "orcamento")
        endpoint = "orcamentos"

    return new Promise((resolve, reject) => {
        axios
            .post(`${enviromentVariables.baseURL}/${endpoint}`, dataToSubmit, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            .then((res) => {
                const retorno = {
                    responseAPI: res.data.mensagem,
                    loading: false,
                    variantAlert: 'success',
                    token: res.data.token
                }
                resolve(retorno)
            })
            .catch((error) => {
                let mensagemErro

                if (error.response) {
                    /* The request was made and the server responded with a status code that falls out of the range of 2xx */
                    console.log('cadastro - error.response.data'); console.log(error.response.data);
                    console.log('cadastro - error.response.status'); console.log(error.response.status);
                    console.log('cadastro - error.response.headers'); console.log(error.response.headers);
                    mensagemErro = error.response.data.mensagemerro;
                } else if (error.request) {
                    /* The request was made but no response was received, `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in Node.js */
                    console.log('cadastro - error.request'); console.log(error.request);
                    mensagemErro = error.request
                } else {
                    /* Something happened in setting up the request and triggered an Error */
                    console.log('cadastro - Error', error.message);
                    mensagemErro = error.message
                }

                console.log('cadastro - error.config'); console.log(error.config);
                const retorno = {
                    responseAPI: 'Erro: ' + mensagemErro,
                    loading: false,
                    variantAlert: 'danger'
                }
                reject(retorno)
            })
    })
}
