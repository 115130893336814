export const SET_ORCAMENTO = "SET_ORCAMENTO";
export const SET_ORIGEM = "SET_ORIGEM";
export const SET_CENTROCUSTO = "SET_CENTROCUSTO";
export const SET_DATA = "SET_DATA";
export const SET_VALOR = "SET_VALOR";
export const SET_DESCRICAO = "SET_DESCRICAO";

export const setOrcamento = (orcamento) => ({
  type: SET_ORCAMENTO,
  payload: orcamento
});

export const setOrigem = (origem) => ({
  type: SET_ORIGEM,
  payload: origem
});

export const setCentroCusto = (centroCusto) => ({
  type: SET_CENTROCUSTO,
  payload: centroCusto
});

export const setData = (data) => ({
  type: SET_DATA,
  payload: data
});

export const setValor = (valor) => ({
  type: SET_VALOR,
  payload: valor
});

export const setDescricao = (descricao) => ({
  type: SET_DESCRICAO,
  payload: descricao
});
