import React from 'react'
import { Form, Button, Spinner, Alert } from 'react-bootstrap'
import { useDispatch, useSelector } from "react-redux";
import { setDenominacao, setValor } from '../actions/orcamentosActions';
import { setResponseAPI, setLoading, setVariantAlert } from '../actions/applicationActions';
import { ApiPost } from '../functions/ApiPost'
import { NavigationBar } from '../components/NavigationBar'

export const Orcamento = () => {
    const dispatch = useDispatch();
    const { denominacao, valor } = useSelector(state => state.orcamentosReducer);
    const { responseAPI, variantAlert, loading } = useSelector(state => state.applicationReducer);

    const handleChange = (event) => {
        event.preventDefault();

        dispatch(setResponseAPI(""))

        if (event.target.id === "formDenominacao") {
            dispatch(setDenominacao(event.target.value))
        } else if (event.target.id === "formValor") {
            dispatch(setValor(event.target.value))
        }
    }

    // envio do formulário
    const handleSubmit = (event) => {
        event.preventDefault();

        /* zera variável de exibição do feedback do envio ao usuário */
        dispatch(setResponseAPI(""));

        // validação preenchimento campos obrigatórios
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        if (form.checkValidity() === true) {
            /* atualiza status de loading no botão de envio */
            dispatch(setLoading(true))

            /* cria objeto com dados que serão enviados */
            const dataToSubmit = {
                denominacao,
                valor
            }

            /* cadastra novo orçamento */
            ApiPost(dataToSubmit, 'orcamento')
                .then((retorno) => {
                    if (retorno) {
                        dispatch(setLoading(retorno.loading));
                        dispatch(setResponseAPI(retorno.responseAPI))
                        dispatch(setVariantAlert(retorno.variantAlert));
                    }
                })
                .catch((error) => {
                    if (error) {
                        dispatch(setLoading(error.loading));
                        dispatch(setResponseAPI(error.responseAPI))
                        dispatch(setVariantAlert(error.variantAlert));
                    }
                })
        }
    }

    return (
        <div>
            <NavigationBar />
            <div>
                <br />
                <h3>Cadastro de Orçamentos</h3>

                <Form onSubmit={handleSubmit} >
                    <Form.Group controlId="formDenominacao" value={denominacao} onChange={handleChange}>
                        <Form.Label>Denominação</Form.Label>
                        <Form.Control type="text" required />
                    </Form.Group>

                    <Form.Group controlId="formValor" value={valor} onChange={handleChange}>
                        <Form.Label>Valor</Form.Label>
                        <Form.Control pattern="\d+(\.\d{2})?|\.\d{2}" required />
                        <Form.Text className="text-muted">Ex.: 1234.99 (informe duas casas decimais, utilizando ponto como separador decimal).</Form.Text>
                    </Form.Group>

                    {(responseAPI !== undefined && responseAPI !== "" && responseAPI !== " ") ? <Alert variant={variantAlert}> {responseAPI} </Alert> : <span></span>}
                    
                    <Button variant="success" type="submit" disabled={loading}>
                        {loading && (<Spinner animation="border" variant="warning" size="sm" />)}
                        {loading && <span> Cadastrando...</span>}
                        {!loading && <span>Cadastrar</span>}
                    </Button>
                </Form>
                <br />
            </div>
            <br />
        </div>
    )
}