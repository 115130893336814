import { useState, useEffect } from "react";
import axios from "axios";
import { enviromentVariables } from '../environment/variables'
import { ApplicationCookies } from '../functions/ApplicationCookies'

export const useOrcamentos = () => {
    const [orcamentos, setOrcamentos] = useState([]);

    const getOrcamentos = () => {
        const { token } = ApplicationCookies()

        /* realiza chamada da API */
        axios
            .get(`${enviromentVariables.baseURL}/orcamentos`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            .then(response => {
                setOrcamentos(response.data.registros)
            })
            .catch((error) => {
                let mensagemErro

                // Error
                if (error.response) {
                    /*
                    * The request was made and the server responded with a
                    * status code that falls out of the range of 2xx
                    */
                    console.log('cadastro - error.response.data'); console.log(error.response.data);
                    console.log('cadastro - error.response.status'); console.log(error.response.status);
                    console.log('cadastro - error.response.headers'); console.log(error.response.headers);
                    mensagemErro = 'cadastro - error.response.data (1): ' + error.response.data.mensagemerro;
                } else if (error.request) {
                    /*
                    * The request was made but no response was received, `error.request`
                    * is an instance of XMLHttpRequest in the browser and an instance
                    * of http.ClientRequest in Node.js
                    */
                    console.log('cadastro - error.request'); console.log(error.request);
                    mensagemErro = 'cadastro - error.request (2): ' + error.request
                } else {
                    // Something happened in setting up the request and triggered an Error
                    console.log('cadastro - Error', error.message);
                    mensagemErro = 'cadastro - Error (3): ' + error.message
                }
                console.log('cadastro - error.config'); console.log(error.config);
                console.log(mensagemErro)
            })
    };

    useEffect(() => {
        getOrcamentos();
    }, []);

    return orcamentos;
};
