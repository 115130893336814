import React from 'react';
import { Route, Switch } from 'react-router-dom'
import { Layout } from './components/Layout'
import {
  Home,
  Orcamento,
  OrcamentoListar,
  Origem,
  OrigemListar,
  CentroCusto,
  CentroCustoListar,
  Lancamento,
  LancamentoListar,
  NoMatch,
  SignUp,
  Unauthorized,
  LogOut,
  LogIn,
  RelatorioOrcamento
} from './pages'
import { ProtectedRoute } from './components/ProtectedRoute'

export const App = () => {

  return (
    <React.Fragment>
      <Layout>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/signup" component={SignUp} />

          <Route exact path='/login' component={LogIn} />
          <Route exact path='/logout' component={LogOut} />

          <ProtectedRoute exact path='/orcamento' component={Orcamento} />
          <ProtectedRoute exact path='/orcamentolistar' component={OrcamentoListar} />
          
          <ProtectedRoute exact path='/origem' component={Origem} />
          <ProtectedRoute exact path='/origemlistar' component={OrigemListar} />
          
          <ProtectedRoute exact path='/centrocusto' component={CentroCusto} />
          <ProtectedRoute exact path='/centrocustolistar' component={CentroCustoListar} />
          
          <ProtectedRoute exact path='/lancamento' component={Lancamento} />
          <ProtectedRoute exact path='/lancamentolistar' component={LancamentoListar} />
          
          <ProtectedRoute exact path='/relatorio-orcamento' component={RelatorioOrcamento} />

          <Route exact path='/unauthorized' component={Unauthorized} />
          <Route component={NoMatch} />
        </Switch>
      </Layout>
    </React.Fragment>
  )
}
