import React from 'react'
import { NavigationBar } from '../components/NavigationBar'

export const NoMatch = () => {
    return (
        <div>
            <NavigationBar />
            <p>Página não encontrada</p>
        </div>
    )
}